body{
    scroll-behavior: smooth;
}
html{
    scroll-behavior: smooth
}
.footer-dot-par {
    position: relative;
}

.footer-dot-par li {
    padding-left: 25px;
    position: relative;
}

.footer-dot-par li::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background-color: #1f86ef;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.scrolll_btn{
    transition: 0.6s all linear;
}
.scrolll_btn.scroll_btn_show{
    transform: translateY(-100%);
    top: 0;
}
.book_taxi{
    position: relative;
    transition: 0.5s ease;
    color: #000;
    font-weight: 600;
}
.book_taxi:hover{
    background-color: transparent;
    color: white;
}
.book_taxi:before {
    content: "";
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #000;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: scale(1, 0);
    -ms-transform: scale(1, 0);
    transform: scale(1, 0);
    z-index: -1;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}
.book_taxi:hover::before {
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
     transform-origin: center center; 
     -webkit-transform: scale(1); 
    -ms-transform: scale(1);
    transform: scale(1);
}
.call_now_btn{
    position: relative;
}
.call_now_btn:hover {
    color: #fff;
    border-color: #1f86ef;
    transition: all ease .5s;
    background-color: transparent;
    z-index: 1;
}
.call_now_btn:hover::before {
    width: 60%;
    height: 100%;
}
.call_now_btn:hover::after {
    width: 60%;
    height: 100%;
}
.call_now_btn::after {
    position: absolute;
    content: "";
    background-color: #1f86ef;
    height: 100%;
    width: 0%;
    border-radius: 5px 0 0 5px;
    -webkit-clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0%, 75% 100%, 0% 100%);
    top: 0;
    left: 0;
    transition: all ease .5s;
    z-index: -1;
}
.call_now_btn::before {
    position: absolute;
    content: "";
    background-color: #202124;
    height: 100%;
    width: 0%;
    border-radius: 0 5px 5px 0;
    -webkit-clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
    bottom: 0;
    right: 0;
    transition: all ease .5s;
    z-index: -1;
}
.car_icon_par{
    display: inline-block;
    width: 60px;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    height: 60px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    outline: 6px solid #ffff;
}
.car_icon_par .car_icon_child{
    font-size: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.dis_scroll{
    overflow: hidden;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.react-datepicker-wrapper{
    width: 100%;
}
.form_shadow{
    position: relative;
    border: 0;
    background: #0597ff;
    border-radius: 8px;
    transform-style: preserve-3d;
    transition: transform 0.3s cubic-bezier(0,0,0.58,1),
    background 0.3s cubic-bezier(0,0,0.58,1);
    cursor: pointer;
    padding: 10px;
}
.form_shadow:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
    filter: blur(4px);
    border-radius: inherit;
    top: 0;
    left: 0;
    transform: translate3d(0, .7em, -1em);
    transition: transform 0.3s cubic-bezier(0,0,0.58,1);
}
.form_shadow:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0ea5e9;
    border-radius: inherit;
    transform: translate3d(0, 0.5em, -1em);
    transition: transform 0.3s cubic-bezier(0,0,0.58,1);
}
.price-card{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
   
}
.price-card>*{
    width: 25%;
  }
.airport_transfer
{
    display: flex;
}
.box{
    box-shadow:inset 0px 0px 2px 0px grey;
}
.trip-card{
    padding: 1rem 0px;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    gap: 8px;
}
.trip-card p{
    display: flex;align-items: center;
    justify-content: center;
    gap: 5px;
}
.trip-card p span{
    font-size: 30px;
    font-weight: 800;
}
.call-btn-cont{
 display: flex;
 justify-content: center;
 align-items: center;
}
.call-btn-cont button{
    text-transform: uppercase;
    letter-spacing: 1px;  
    font-weight: 700;
    font-size: 13px;
    background-color: #fcb708;
    padding: 0.75rem 2rem; 
    border-radius:2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.car-title{
    text-align: center;
    padding: 1rem 0px;
    font-weight: 700;
    text-transform: uppercase;
}
.car-container{
    position: relative;
    overflow: hidden;
}
.ribbon{
   
    width: 150px;
    height: 150px;
    position:absolute;
    top:-50px;
    right: -30px;

}
.ribbon span{

    text-transform: uppercase;
    background-color: #fcb708;
    position: absolute;
    font-size: 12px;
    font-weight:600;
    top: 50%;
    right: -10px;
    width: 100%;
    padding: 0.25rem 0px;
    text-align: center;
    transform: rotate(45deg);
    box-shadow: 5px 5px 5px grey;
}
.chooseus-cont{

    padding: 3rem 2rem;
    display: flex;
    gap:50px;
    justify-content: center;
}

.choose-card{
    
    width:350px;
    padding: 2rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow:  0px 0px 10px rgb(189, 189, 189);
}
.icon{
    font-size: 50px;
    color: #fcb708;
}
.title{
    padding: 1rem 0px;
    font-size: 18px;
    font-weight: 700;
    color: #1e1e1e;
}
.desc{
    padding-bottom:1rem;
    font-weight: 400;
}
.call-btn{
    color: #fcb708;
    font-weight:700;
    font-size: 13px;
    text-transform: uppercase;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.tourist-grid-title{
    font-size: 40px;

    text-align: center;
    font-weight: 700;
}
.tourist-grid-desc{
    font-size: 18px;
    text-align: center;
    color: #fcb708;
    font-weight: 600;
}
.overlay{
    background-color:#0d233e91;
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
}
.overlay span{
    background-color: #fcb708;
    color:black;
    padding:0.25rem 0.5rem;
    font-size: 12px;
    font-weight: 700;
    border-radius: 0.25rem;
    text-transform: uppercase;
  
}
.overlay div{
    position: absolute;
    color: white;
    bottom: 0;
    transform: translateY(45%);
    transition: transform 300ms;
}
.overlay-child .city{
    
    font-size: 17px;
    text-transform: capitalize;
    font-weight: 600;
}
.overlay-child .places{
    
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 600;
}
.overlay:hover .overlay-child{
    transform:translateY(-50%);
}


.tourist-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 25px;
}
.grid-items{
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: transform 500ms;
  
  }
.grid-items:hover{
   transform: translateY(-2%);
}
.form-title{
    font-size: 20px;
    font-weight: 600;

}
.enquiry-form div{
    padding-bottom:1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.enquiry-form div label{
    font-size: 18px;
}
.enquiry-form div input,select,textarea{
 outline: none;
 width: 60%;
 border: 1px solid rgb(181, 181, 181);
 padding: 0.5rem;
 border-radius: 0.5rem;
}
.enquiry-form div select option:checked {
    background-color: #fcb708; 
    color: #fff; 
}

.enquiry-form .btn-container {
    display: flex;
    justify-content: end;
    gap: 10px;
}
.enquiry-form .btn-container input{
  width: 100px;
}
.enquiry-form div input[type="submit"]{
    background-color: #fcb708;
    font-weight: 600;
    border: none;
}
.enquiry-form div input[type="button"]{
    background-color: black;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    border: none;
}
.pagebanner{
    background-attachment: fixed;
    padding: 3rem 1rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.banner-overlay{
    background-color: rgba(135, 135, 135, 0.315);
    padding: 1rem;
}
.ex-card{
    display: grid;
    gap:20px;
    grid-template-columns: 1fr 1fr 1fr;

}

.ex-cont{
    position: relative;
  
}
.ex-img{
    
   min-height: 200px;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;

}
.route-card{
    padding: 2rem 0px;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
}
.route-card1{
    padding: 2rem 0px;
    display: flex;
    flex-direction: column;
    place-items: center;
    justify-content: space-between;
    row-gap: 10px;
}
.route-card1 h5:nth-child(1)
{
    font-weight: 700;
    text-transform:uppercase;
}
.route-card1 h5:nth-child(3)
{
    display: flex;
    align-items: center;
    padding:0;
    margin: 0;
    gap: 10px;
    text-transform: capitalize;
    font-size: 15px;
 
   
   
}

.route-card1 h5:nth-child(3) span
{
    color: #fcb708;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.call-info{
    display: flex;
    align-items: center;
    padding: 2rem 0px 1rem 0px;
    gap: 10px;
 }
.call-info span{
    background-color: #fcb708;
    color: #000;
    border-radius: 50%;
    width: 50px;
    height:50px;
    display: grid;
    place-items: center;
    aspect-ratio: 1/1;
}
.call-info div p:nth-child(1)
{
    color: #5e5e5e;
    font-weight: 600;
}
.call-info div p:nth-child(2)
{
    font-weight: 700;
    font-size: 20px;
}
.menu-bar{
    display: flex;
    gap: 5px;
    align-items:center;
    position: relative;
}
.menu-bar span{
    font-size: 20px;
}
.dropdown {
    display:none;
    transition: all 500ms;
}
.menu-bar:hover .dropdown 
{
    display: block;
    position: absolute;
    z-index: 111;
    top: 100%;
    left: 0;
    background-color:#fcb708;
    width:250px;
}
.dropdown ul{
    display: block;
    margin: 10px;
}
.dropdown ul li{
  padding: 10px;
  
}
.dropdown ul li a{
    color: black;
}
.dropdown ul li a:hover{
    color: white;
}
.domestic-cont{
    display: flex;
    min-height: 500px;
}
.domestic-card{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.domestic-overlay{
    background-image: linear-gradient(rgba(40, 40, 40, 0.238),rgba(0, 0, 0, 0.836));
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 2rem  2rem 4rem 2rem;
    align-items: start;
}
.domestic-overlay span{
    background-color: #fcb708;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0.25rem 0.5rem;
    text-transform:uppercase;
    font-weight: 700;  
    border-radius: 0.25rem;
}
.domestic-overlay div h4{
    color: white;
    font-weight: 700;
    font-size: 22px;
    text-transform: capitalize;
    padding: 1rem 0px;
}
.domestic-overlay div p{
    color: rgba(255, 255, 255, 0.571);
    font-weight: 600;
}
.inter-cont{
     display: flex;
     flex-wrap: wrap;
     gap: 20px;
    padding: 0px 5rem;
}
.inter-card{
   width: min(100%,25rem);
   min-height:300px;
   background-position: center center;
   background-size: cover;
   background-repeat: no-repeat;
   border-radius: 10px; 
   overflow: hidden;
}
.inter-card span{
    background-color: #fcb708;
    display: flex;
    align-items: center;
    gap: 10px;
    text-transform: uppercase;
    font-weight: 700;

}
.inter-overlay {
    background-color: rgba(31, 31, 31, 0.34);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 2rem;
    align-items: start;
}
.inter-overlay span{
    background-color: #fcb708;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0.25rem 0.5rem;
    text-transform:uppercase;
    font-weight: 700;  
    border-radius: 0.25rem;
}
.inter-overlay p{
    color: white;
    text-transform: uppercase;
}
.d-cont{
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 10px;
}
.d-pack
{
    background-color: white;
    display: flex;
    padding: 1.5rem;
    gap: 40px;
}
.d-img{
   width: 40%;
   min-height: 350px;
   background-position: center center;
   background-repeat: no-repeat;
   background-size: cover;
}
.d-info{
     display: flex;
     flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 60%;
  
}
.d-info h5{
 text-transform: uppercase;
 font-weight: 700;
 text-align: center;
 font-size: 20px;
 padding: 1rem 0px;
}
.pack-route
{
   font-size:18px;
   display: flex;
   align-items: start;
   gap: 15px; 
   padding: 0;
   
}
.pack-route span{

    padding-top: 0.5rem;
  
 color: #fcb708;
}
.pack-duration 
{
    display: flex;
    align-items: center;
    gap:15px;
}
.pack-duration span:nth-child(2) 
{

   font-weight: 700;
   font-size: 16px;
   padding: 0;
}
.pack-duration span:nth-child(1) 
{
 color: #fcb708;
}
.rooms-cont{
    padding: 3rem 0px;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content:center center;
 }
 
 .rooms-cont div{
    min-height: 300px;
    width: 32%;
    background-color: white;
    background-image: cover;
    background-repeat: no-repeat;
    background-position: center;
 }
 
 .booking-form-cont{
    background-color: white;
    padding: 2rem 1.5rem;
 }
 .booking-form h4{
    font-size: 25px;
    font-weight: 500;
 }
 .booking-form label{
    font-weight: 600;
    font-size: 18px;
 }
 .booking-form label span{
    color: crimson;
    font-weight: 600;
 }
 .input-container{
    display: flex;
    padding: 2rem 0px;

 }
 .input-container input,select{
    border: 2px solid rgb(193, 193, 193);
    width: 75%;
    padding: 0.5rem;
    border-radius: 0;
 }
 .input-container >*{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
 }
 .message-container{
 
    display: flex;
    flex-direction: column;
    gap: 10px;
 }
 .message-container textarea{
    width: 100%;
    border-radius: 0;
 }
 .booking-submit{
    padding: 1rem 0px;
 }
 .booking-submit button{
    background-color: #fcb708;
    padding: 1rem 1.5rem;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    width: 200px;
    cursor: pointer;
 }
 
 .review-cont{
    width: min(90%,95rem    );


 }

 .review-card{
    background-color: white;
    position: relative;
    padding: 2.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0.2rem;
    box-shadow:  0px 0px 10px rgb(189, 189, 189); 
    min-height:420px;}

 .review-card span{
    background-color: #fcb708;
    width: 70px;
    height: 70px;
    position: absolute;
    top: 0%;
    left: 50%;
    font-size: 20px;
    font-weight: 600;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    outline: 5px solid #d1d5db;
 }
 .review-card h4{
  font-weight: 600;
  font-size: 18px;
  text-transform: capitalize;
 }
 .review-card p{
    font-size: 15px;
    text-align: justify;
    color: #2e2e2e;
 }
 .review-rate{
    color: #fcb708;
    display: flex;
    gap: 8px;
    padding: 1rem 0px;
 }


 .slick-prev:before,
.slick-next:before {
  color:#fcb708;
  font-size: 30px;
 /* Change to the desired color */
}
 .slick-slide{
    padding: 3rem  0px 1rem 0px;


 }
 .slick-slide >div{
    margin: 0 10px;
 

 
 }
 .slick-dots li button:before {
    color: #333;
    font-size: 10px; /* Dot color */
    
  }
@media (max-width: 768px)
{
    .chooseus-cont{
        flex-direction: column;
        padding: 3rem 1rem;
    }
    .choose-card{
        width: 95%;
    }
    .price-car,.airport_transfer{
        flex-direction: column;
        gap:20px;  
      }
     
      .price-card >*{
        width: 100%;
      }
      .tourist-grid{ 
        grid-template-columns: 1fr ;
  
    }
    .tourist-grid-title{
        font-size:20px;
    }
    .tourist-grid-desc{
        font-size: 10px;
   
    }
    .ex-card{
        grid-template-columns: 1fr;
    }
    .domestic-cont{
        gap: 10px;
        flex-direction: column;
    }
    .menu-bar span{
        rotate:-90deg;
       
    }
    .menu-bar:hover .dropdown 
{
    display: block;
    position: absolute;
    z-index: 111;
    top: 0;
    transform: translateY(-82%);
    left:50%;
    background-color:white;
    width:250px;
}
.dropdown ul li{
    border-bottom: 1px solid  rgb(174, 174, 174);
}
.inter-cont{
    padding: 0;
}
.d-pack{
    flex-direction: column;
    padding: 1rem;
    
}
.d-img{
    width: 100%;
    min-height: 250px;
 }
 .d-info{
    width: 100%;
 }
 .pack-route
 {
    font-size: 15px;
 }
 .rooms-cont div{
    width: 100%;
 }
 .input-container{
  flex-direction: column;
  padding: 0.5rem 0px;
  gap: 10px;
 }
 .input-container input,select{
    width: 100%;
   
 }
 .booking-submit button
 {
    width: 100%;
 }
.error{
    width: 100%;
}
}