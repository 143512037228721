
table,caption{

    padding: 1rem;
    width:100%;
    
}
caption{
    font-size: 16px;
    font-weight: 600;
}
th,td{
    padding: 8px;
    text-align: center;
  
}
tr:nth-of-type(2n)
{
    background-color: rgb(244, 244, 244);
}
.table-cont{
    border-radius: 5px;
    background-color: white;
    padding: 1rem 1rem;

   
  
}
.book-btn{
    background-color: #fcb708;
    width: 100%;
    min-height: 45px;
    font-weight: 600;
    margin-top: 10px;
    border-radius: 5px;
    text-transform: uppercase;
}

.acc-table1, .acc-table2{
    background-color: white;
  
}
.acc-table1 caption,.acc-table2 caption{
    background-color: white;
     margin-bottom: 10px;
    font-weight: 600;
    font-size: 17px;
     padding: 1rem 0px;
}
.acc-table1 th,td , .acc-table2 th,td{
    padding: 1rem;
    text-align: center;
  
}
.acc-table2 td
{
    border: 1px solid rgb(221, 221, 221);
}
.star
{   
    color: #fcb708;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


@media (max-width:768px) {
    .acc-table1 th,td{
        padding: 12px;
        text-align: center;
      
    }
}